import GoogleApi from "./GoogleApi"

export class TextFieldController {
    enterDidTap(name: string, phone: string): string {
        if (!this.isPhoneCorrect(phone)) {
            return "Некорректный формат телефона"
        }
        
        if (name.length == 0) {
            return "Имя не может быть пустым"
        }
        console.log("enterDidTap", phone);


        return ""
    }

    isPhoneCorrect(phone: string): boolean {
        console.log("isCorrect", phone);
        return !phone.includes("_") && phone.length != 0
    }

    sendData(name: string, phone: string) {
        console.log("sendData", phone);
        let googleApi = new GoogleApi('1Ua_gXH9aG_-38ACbZNWvv0ZAQmryMBNDN3h45O27z4c')
        googleApi.postRow([name, `8 ${phone}`])
    }

    phoneMask(number: string, addingChar: string = "") {
        const pattern = /^\([_\d]{3}\) [_\d]{3}-[_\d]{2}-[_\d]{2}$/g;

        // Удаление последней цифры
        if (addingChar == "") {
            let index: number = -1
            for (let i = number.length; i >= 0; i--) {
                if ("0123456789".includes(number[i])) {
                    console.log("ADDING CHAR", number)
                    index = i
                    break
                }
            }

            let arr = number.split('')
            arr[index] = "_"
            console.debug(arr)
            console.log("phoneMusk, if part", number);

            return arr.join("")
        }

        let numberCopy = number
        if (numberCopy.length == 0) {
            numberCopy = "(___) ___-__-__"
        }
        console.log("phoneMusk, copy", number);
        // Добавление цифры
        let index = numberCopy.indexOf("_")
        if (index == -1)
            return numberCopy

        let arr = numberCopy.split('')
        arr[index] = addingChar
        console.log("index", index)
        console.log("phoneMusk, return join", arr);

        return arr.join('')
    }

    containsDigit(number: string): boolean {
        for (let i = 0; i < number.length; i++) {
            if ("0123456789".includes(number[i])) {
                console.log("containDigit true", number);

                return true
            }
        }
        console.log("containDigit false", number);

        return false
    }
}