import styles from './AboutInvitation.module.css'
import { useState, useEffect } from "react";
import { NavbarViewController } from '../../navbar/NavbarViewController.ts';

import desertBig from '../../../assets/img/DesertAbout.png'
import black from '../../../assets/img/BlackAbout.png'
import emerald from '../../../assets/img/EmeraldAbout.png'
import party from '../../../assets/img/PartyAbout.png'
import gold from '../../../assets/img/GoldAbout.svg'


export default function AboutInvitation() {
  const [isMounted, setMounted] = useState(false)
  const [navController, _] = useState(NavbarViewController.shared)

  useEffect(() => {
    setMounted(true)
  }, [])

  const onClickOrder = (e) => {
    navController.scrollToOrder()
  }

  return(
    <div className={styles.info__container}>
      <h2 className={styles.title}>Немного об электронном пригласительном</h2>
      <div className={styles.info_grid}>
        <div className={styles.info__big_img}>
          <img className={styles.big_img} src={desertBig} width='900' height='675'/>
          <p className={styles.maket_name}>Desert</p>
          <button className={styles.btn_order} onClick={onClickOrder}>заказать</button>
        </div>

        <div className={styles.info_img}>
          <p className={styles.title_list}>Вы можете изменить или добавить в пригласительное:</p>
          <ul className={styles.list_marker}>
              <li className={styles.list_item}>Дата, имена и адреса</li>
              <li className={styles.list_item}>Внести изменения в программу дня и добавить дополнительные иконки</li>
              <li className={styles.list_item}>Изменить пожелания и цветовую палитру</li>
              <li className={styles.list_item}>Внести корректировки в анкету гостя</li>
          </ul>
          
          <div className={styles.info__imgs}>
            <img className={styles.info__img} src={black} width='446' height='335'/>
            <img className={styles.info__img} src={emerald} width='446' height='335'/>
            <img className={styles.info__img} src={party} width='446' height='335'/>
            { isMounted && window.innerWidth > 800 ?
              <img className={styles.info__img} src={gold} width='446' height='335'/>
              :
              <></>
            }
            
          </div>
        </div>
      </div>
    </div>
  )
}