import { useState, useEffect } from "react"
import { TextFieldController } from "../Controller/TextFieldController.ts"
import styles from './TextField.module.css'

export default function TextField(props) {
  const [value, setValue] = useState('')
  const onChange = props.onChange
  const controller = new TextFieldController()

  const onKeyDown = (event) => {
    event.preventDefault()
    // Backspace 8
    // Enter 13
    var charCode = (event.which) ? event.which : event.keyCode
    
    if (charCode >= 48 && charCode <= 57 && (value.includes("_") || value.length == 0)) {
      console.log("AAAAAA", value)
      setValue(controller.phoneMask(value, event.key))
      onChange("8" + value)
      return
    }

    if (charCode == 8) {
      const newValue = controller.phoneMask(value)
      if (controller.containsDigit(newValue)){
        console.log("TFController, if", value, newValue);
        setValue(newValue)
      }
      else {
        setValue("")
      }
      onChange("8" + value)
      
      return
    }

    if (charCode == 13) {
      const name = props.name
      const phone = value 
      let error = controller.enterDidTap(name, phone)
      if (error.length > 0) {
        return
      }
      onChange("8" + value)
      controller.sendData(name, phone)
    }
    onChange("8" + value)
  }

  return (
    <div className={`${styles.textfield__container} ${props.className}`}>
      <div className={styles.textfield__region}>+7</div>
      <input className={`${styles.textfield__input} order__phone-input`} onKeyDown={onKeyDown} placeholder='(999) 000 00 00' value={value}/>
    </div>
  )
}